export default {
    defaultsRoutes: [
        {
            id: 1,
            name: 'Login',
            title: 'Вход',
            hash: ''
        },
        {
            id: 2,
            name: 'Registration',
            title: 'Регистрация',
            hash: ''
        }
    ]
}


