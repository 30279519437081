<template>
    <div>
        <vue-title title="Сбросить пароль | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsRoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Сбросить пароль</h1>
            </div>
            <div class="row">
                <div class="col-12 col-md-2 col-lg-3"></div>
                <div class="col-12 col-md-8 col-lg-6">
                    <div v-if="isResetPasswordOk">
                        <div class="alert alert-success mb-3" role="alert">
                            Новый пароль выслан вам на Email, указанный при регистрации.
                        </div>
                        <hr>
                        <div class="text-center">Уже получили пароль?
                            <DxButton
                                    text="Вход"
                                    type="default"
                                    styling-mode="outlined"
                                    @click="openLogin"
                                    class="ml-3"
                            />
                        </div>
                    </div>
                    <div id="form-container" v-if="!isResetPasswordOk">
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="login-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isLoading"
                            >
                                <DxSimpleItem data-field="Login">
                                    <DxLabel text="Логин"/>
                                    <DxRequiredRule message="Укажите ваш логин"/>
                                </DxSimpleItem>
                                <DxGroupItem :colCount="1" :alignItemLabels="true">
                                    <DxButtonItem
                                            horizontalAlignment="right"
                                            cssClass="pt-3"
                                            :button-options="buttonResetPasswordOptions"
                                    />
                                </DxGroupItem>
                            </DxForm>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-md-2 col-lg-3"></div>
                <div class="col-12 col-md-2"></div>
                <div class="col-12 col-md-8 mt-5 small">
                    <p>
                        * Новый пароль будет выслан вам на Email, указанный при регистрации.
                    </p>
                    <p>
                        ** Для корректной работы сайта в настройках веб-браузера должна быть разрешена работа с <a
                            href="https://ru.wikipedia.org/wiki/JavaScript" target="_blank"><strong>JavaScript</strong></a>
                        и
                        <a href="https://ru.wikipedia.org/wiki/HTTP_cookie" target="_blank"><strong>HTTP cookie</strong></a>.
                    </p>
                </div>
                <div class="col-12 col-md-2"></div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from '../header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../../shared/services';
    import {Header, Footer} from '../../../shared/components';
    import {DxForm, DxSimpleItem, DxLabel, DxButtonItem, DxRequiredRule, DxGroupItem} from 'devextreme-vue/form';
    import DxButton from 'devextreme-vue/button';

    export default {
        name: 'ResetPassword',
        components: {
            Header,
            Footer,
            DxForm,
            DxSimpleItem,
            DxLabel,
            DxButtonItem,
            DxRequiredRule,
            DxGroupItem,
            DxButton
        },
        mounted() {
            window.errorMessage = "";
        },
        data() {
            return {
                headerRoutes,
                formData: {},
                labelLocation: 'top',
                isResetPasswordOk: false,
                buttonResetPasswordOptions: {
                    text: 'Сбросить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                errorMessage: ""
            }
        },
        methods: {
            onSubmit: async function () {
                this.errorMessage = "";
                this.$store.dispatch('loading', true).then(() => {
                    const {Login} = this.formData;

                    httpClient.post(`v2/Agent/ResetPassword/${Login}`).then(() => {
                        this.$store.dispatch('loading', false);
                        this.isResetPasswordOk = true;
                    }).catch(this.handleError);
                });
            },
            handleError: function (error) {
                this.errorMessage = "Неверно указан Логин.";
                this.$store.dispatch('loading', false);
                console.error(error);
            },
            openLogin() {
                if (this.$route.query.redirect) {
                    this.$router.push({name: 'Login', query: {redirect: this.$route.query.redirect}});
                } else {
                    this.$router.push({name: 'Login'});
                }
            }
        },
        computed: {
            ...mapGetters([
                'isLoading'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
